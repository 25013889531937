import {
  BoundDateField,
  BoundNumberField,
  Button,
  FormLines,
  ModalBody,
  ModalFooter,
  ModalHeader,
  useModal,
} from "@homebound/beam";
import { ObjectConfig, required, useFormState } from "@homebound/form-state";
import { Observer } from "mobx-react";
import { useHistory } from "react-router";
import {
  SaveCreditFacilityInvoiceInput,
  useCreditFacilityInvoicePopulatedQuery,
  useQueueJobsMutation,
  useSaveCreditFacilityInvoiceMutation,
} from "src/generated/graphql-types";
import { createInvoiceDetailsUrl } from "src/RouteUrls";
import { DateOnly } from "src/utils/dates";
import { wait } from "src/utils/utils";

type CreateInvoiceModalProps = {
  fundId: string;
};

export function CreateInvoiceModal({ fundId }: CreateInvoiceModalProps) {
  const formState = useFormState({
    config: formConfig,
    init: {
      amountInCents: 0,
      invoiceDate: new DateOnly(new Date()),
    },
  });
  const { closeModal } = useModal();
  const [saveCreditFacilityInvoice] = useSaveCreditFacilityInvoiceMutation();
  const [queueJobs] = useQueueJobsMutation();

  const creditFacilityInvoicePopulatedQuery = useCreditFacilityInvoicePopulatedQuery({
    variables: { filter: { fund: [fundId], isInterestPopulated: true } },
  });

  const history = useHistory();

  return (
    <>
      <ModalHeader>Create Credit Facility Invoice</ModalHeader>
      <ModalBody>
        <FormLines>
          <BoundDateField field={formState.invoiceDate} label="Date" />
          <BoundNumberField field={formState.amountInCents} type="cents" label="Invoice Amount" />
        </FormLines>
      </ModalBody>
      <ModalFooter>
        <Observer>
          {() => (
            <>
              <Button label="Cancel" onClick={closeModal} variant="secondary" />
              <Button
                disabled={!formState.valid}
                label="Create"
                onClick={async () => {
                  if (formState.canSave()) {
                    const response = await saveCreditFacilityInvoice({
                      variables: {
                        input: {
                          ...mapToInput(formState.value),
                          fundId,
                        },
                      },
                    });
                    const newInvoiceId = response.data?.saveCreditFacilityInvoice.creditFacilityInvoice.id;
                    if (newInvoiceId) {
                      // Queue up job to populate the new invoice with interest payments
                      await queueJobs({
                        variables: {
                          input: {
                            name: "populateCreditFacilityInvoice",
                            payloads: [{ creditFacilityInvoiceId: newInvoiceId }],
                          },
                        },
                      });
                      // Then loop until we see the new invoice has been populated
                      while (true) {
                        await wait(5);
                        // Poll until we see the new invoice has been populated
                        const populatedInvoices =
                          (await creditFacilityInvoicePopulatedQuery.refetch()).data?.creditFacilityInvoices || [];

                        if (populatedInvoices.filter((invoice) => invoice.id === newInvoiceId).length > 0) {
                          break;
                        }
                      }
                      // close global modal then redirect
                      closeModal();
                      history.push({ pathname: createInvoiceDetailsUrl(newInvoiceId) });
                    }
                  }
                }}
              />
            </>
          )}
        </Observer>
      </ModalFooter>
    </>
  );
}

type FormInput = Pick<SaveCreditFacilityInvoiceInput, "amountInCents"> & {
  invoiceDate: Date | null | undefined;
};

const formConfig: ObjectConfig<FormInput> = {
  invoiceDate: { type: "value", rules: [required] },
  amountInCents: { type: "value", rules: [required] },
};

function mapToInput(form: FormInput): SaveCreditFacilityInvoiceInput {
  const { invoiceDate, ...others } = form;
  return {
    invoiceDate: invoiceDate && new DateOnly(invoiceDate),
    ...others,
  };
}
