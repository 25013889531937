import {
  Button,
  column,
  dateColumn,
  GridCellContent,
  GridColumn,
  GridDataRow,
  GridTable,
  RowStyles,
  simpleDataRows,
  SimpleHeaderAndData,
  useGridTableApi,
} from "@homebound/beam";
import { percentCellContent, priceCellContent } from "src/components";
import { FundOverviewPageFundFragment, FundOverviewPageLotPartitionFragment } from "src/generated/graphql-types";
import { formatWithYear } from "src/utils/dates";

export type PerformanceTableProps = {
  fund: FundOverviewPageFundFragment;
};

export function PerformanceTable(props: PerformanceTableProps) {
  const { fund } = props;

  const lotPartitions = fund.lots
    .flatMap((lot) => lot.partitions)
    .compact()
    .filter((partition) => partition.lot.fundContributionDate); // Only show ones that we have actually acquired

  const tableApi = useGridTableApi<Row>();

  return (
    <>
      <GridTable
        id="performanceTable"
        rowStyles={rowStyles}
        sorting={{ on: "client" }}
        columns={createPerformanceColumns()}
        rows={createLotRows(lotPartitions)}
        fallbackMessage="Performance for the Investments in this Fund will show here."
        api={tableApi}
      />
      <Button onClick={() => tableApi.downloadToCsv("FundPerformance.csv")} label="Download CSV" />
    </>
  );
}

type Row = SimpleHeaderAndData<FundOverviewPageLotPartitionFragment>;

const rowStyles: RowStyles<Row> = {
  header: {},
  data: {},
};

function createLotRows(lots: FundOverviewPageLotPartitionFragment[]): GridDataRow<Row>[] {
  return simpleDataRows(lots);
}

function createPerformanceColumns(): GridColumn<Row>[] {
  const idColumn = column<Row>({
    header: () => "Lot Partition ID",
    data: ({ id }) => id,
    sticky: "left",
  });

  const blueprintIdColumn = column<Row>({
    header: () => "Blueprint ID",
    data: ({ blueprintProjectId }) => blueprintProjectId,
    sticky: "left",
  });

  const addressColumn = column<Row>({
    header: () => "Lot Address",
    data: ({ lot }) => lot.address?.street1,
    sticky: "left",
  });

  const purchasePriceColumn = column<Row>({
    header: () => "Purchase Price",
    data: ({ lot, underwrittenLotOwnershipBasisPoints }) =>
      amountGridCellContent(Math.round((lot.purchasePriceInCents * underwrittenLotOwnershipBasisPoints) / 100_00)),
  });

  const lotMarkupColumn = column<Row>({
    header: () => "Lot Markup",
    data: ({ lot, underwrittenLotOwnershipBasisPoints }) =>
      amountGridCellContent(
        Math.round((lot.opcoSiteAcquisitionMarkupInCents * underwrittenLotOwnershipBasisPoints) / 100_00),
      ),
  });

  const fundContributionDateColumn = dateColumn<Row>({
    header: () => "Fund Contribution Date",
    data: ({ lot }) => (lot.fundContributionDate ? formatWithYear(lot.fundContributionDate) : null),
  });

  const constructionCostColumn = column<Row>({
    header: () => "Construction Cost",
    data: ({ softCostInCents, indirectHardCostInCents, directHardCostInCents }) =>
      amountGridCellContent(softCostInCents + indirectHardCostInCents + directHardCostInCents),
  });

  const builderFeeColumn = column<Row>({
    header: () => "Builder Fee",
    data: ({ opcoMarkupInCents }) => amountGridCellContent(opcoMarkupInCents),
  });

  const netSalesPriceColumn = column<Row>({
    header: () => "Net Sales Price",
    data: ({ netSalesPriceInCents }) => amountGridCellContent(netSalesPriceInCents),
  });

  const salesStatusColumn = column<Row>({
    header: () => "Sales Status",
    data: ({ lotPartitionSalesStatus }) => lotPartitionSalesStatus.name,
  });

  const saleDateColumn = dateColumn<Row>({
    header: () => "Sold Date",
    data: ({ saleDate }) => (saleDate ? formatWithYear(saleDate) : null),
  });

  const realizedIRRColumn = dateColumn<Row>({
    header: () => "Realized IRR (Unlevered)",
    data: ({ realizedUnleveredInternalRateOfReturnBasisPoints }) =>
      realizedUnleveredInternalRateOfReturnBasisPoints
        ? amountGridCellContent(realizedUnleveredInternalRateOfReturnBasisPoints / 100, true)
        : null,
  });

  const realizedMOICColumn = dateColumn<Row>({
    header: () => "Realized MOIC (Unlevered)",
    data: ({ realizedUnleveredMultipleOnInvestedCapital }) => realizedUnleveredMultipleOnInvestedCapital,
  });

  const realizedDebtInterestColumn = dateColumn<Row>({
    header: () => "Realized Debt Interest",
    data: ({ draws }) =>
      amountGridCellContent(
        draws.map((draw) => draw.invoices.map((invoice) => invoice.interestAmountInCents).sum()).sum(),
      ),
  });

  const realizedLeveredIRRColumn = dateColumn<Row>({
    header: () => "Realized IRR (Levered)",
    data: ({ realizedLeveredInternalRateOfReturnBasisPoints }) =>
      realizedLeveredInternalRateOfReturnBasisPoints
        ? amountGridCellContent(realizedLeveredInternalRateOfReturnBasisPoints / 100, true)
        : null,
  });

  const realizedLeveredMOICColumn = dateColumn<Row>({
    header: () => "Realized MOIC (Levered)",
    data: ({ realizedLeveredMultipleOnInvestedCapital }) => realizedLeveredMultipleOnInvestedCapital,
  });

  const directMarginColumn = dateColumn<Row>({
    header: () => "Direct Margin",
    data: ({ directMarginBasisPoints }) => amountGridCellContent(directMarginBasisPoints / 100, true),
  });

  return [
    idColumn,
    blueprintIdColumn,
    addressColumn,
    fundContributionDateColumn,
    purchasePriceColumn,
    lotMarkupColumn,
    constructionCostColumn,
    builderFeeColumn,
    netSalesPriceColumn,
    salesStatusColumn,
    saleDateColumn,
    realizedIRRColumn,
    realizedMOICColumn,
    realizedDebtInterestColumn,
    realizedLeveredIRRColumn,
    realizedLeveredMOICColumn,
    directMarginColumn,
  ];
}

function amountGridCellContent(amount: number, usePercent = false): GridCellContent {
  return {
    alignment: "left",
    content: usePercent ? percentCellContent(amount) : priceCellContent(amount),
    value: usePercent ? amount : amount / 100, // Because this is the value printed by the CSV download
  };
}
